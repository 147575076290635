import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "./AuthWrapper.js";
import {
  q_tok_balance,
  q_token_prices,
  q_vaultinfo,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { dec, getv, jparse, jstr, nils, toeth } from "../utils/utils.js";
import _ from "lodash";
import { set_state_ob } from "../components/input.js";
import { useMetaMaskContext } from "./MetaMaskWrapper.js";
import { contractAddress_list } from "../contracts/constants.js";
import { twMerge } from "tailwind-merge";
import { Tag, TokenIcon, ToolTip } from "../components/utilityComps.js";
import { mm_asset_signer } from "../views/assetsinfolist.js";
import { tokdecn } from "../App.js";

export const AccountContext = createContext({});
export const useAccountContext = () => useContext(AccountContext);

const def_acc_config = {
  mode: "all",
  vaulthconfs: {},
};
const token = "DEZ";
export const bet_token = token;
export const bet_paytoken_addr =
  bet_token == "WETH"
    ? contractAddress_list.weth
    : bet_token == "DEZ"
    ? contractAddress_list.dez
    : null;

export default function AccountWrapper(props) {
  const aucon = useAuthContext();
  const { auth, vault } = aucon;
  const [qo_vaultinfo, qo_balance] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_tok_balance(
      {
        wallet: vault,
        tokenaddr: bet_paytoken_addr,
      },
      { staleTime: 30 * 1e3, refetchInterval: 30 * 1e3, enabled: auth }
    ),
  ]);

  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const acc_config_locpath = `acc_config-${vault}`;
  const [acc_config, set_acc_config] = useState(
    jparse(localStorage.getItem(acc_config_locpath), def_acc_config)
  );
  useEffect(() => {
    if (_.isEmpty(acc_config)) {
      let ob = jparse(localStorage.getItem(acc_config_locpath), def_acc_config);
      localStorage.setItem(acc_config_locpath, jstr(def_acc_config));
      set_acc_config(ob);
    } else {
      localStorage.setItem(acc_config_locpath, jstr(acc_config));
    }
  }, [acc_config]);
  const s_acc_config = (p, v) => {
    set_state_ob(acc_config, set_acc_config, p, v);
  };
  const g_acc_config = (p, def) => {
    return getv(acc_config, p) ?? def;
  };

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tok_ethusd = useMemo(
    () => getv(qo_tokpri, "data.result.ethusd"),
    [qo_tokpri.dataUpdatedAt]
  );

  const balance = useMemo(
    () => getv(qo_balance, "data.result.bal", undefined),
    [qo_balance.dataUpdatedAt]
  );

  const accon = {
    vault,

    vdoc,

    qo_vaultinfo,

    acc_config,
    set_acc_config,
    s_acc_config,
    g_acc_config,

    qo_balance,
    balance,

    tok_ethusd,
  };
  return (
    <AccountContext.Provider value={accon}>
      {props.children}
    </AccountContext.Provider>
  );
}

export const AccountBalanceNav = () => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const { auth } = aucon;
  const { balance, market_allowance } = accon;

  if (!auth) return <></>;

  return (
    <div className="fr-sc resp-gap-2">
      <ToolTip
        message={"Account Balance"}
        msg_cn="top-[3.5rem] resp-text--2 w-max"
      >
        <Tag
          className={twMerge(
            "rounded-full bg-acc0 text-white bg-lig/80 fr-sc resp-gap-1",
            "fr-sc"
          )}
        >
          <TokenIcon token={token} size="xs" />
          <span className="resp-text--2">
            {nils(balance) ? "--" : dec(balance, tokdecn(token))}
          </span>
        </Tag>
      </ToolTip>
    </div>
  );
};
