import { ethers } from "ethers";
import { iso, nano, nils, toeth, tofeth } from "../../utils/utils.js";
import abi from "./abi.json";
import { contractAddress_list } from "../constants";

const getgasinfo = () => ({
  // gasLimit: 220000,
});
const blocktime_iso = (n) => iso(Number(n) * 1000);

class FairexMCBetsContract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async setPaymentToken(_skinCon, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setPaymentToken(_skinCon, info);
    const resp = await tx.wait();
    return resp;
  }

  get_status(s) {
    if (s == 0) return "pending";
    if (s == 1) return "win";
    if (s == 2) return "lose";
    if (s == 3) return "refunded";
    if (s == 4) return "cancelled";
    return "unknown";
  }

  parseBet(a) {
    let i = 0;
    let o = {
      betid: a[i++].toLowerCase(),
      bettor: a[i++].toLowerCase(),
      settled: a[i++],
      rid: a[i++],
      hid: Number(a[i++]),
      betamt: a[i++],
      winamt: a[i++],
      statuscode: Number(a[i++]),
      created_at: Number(a[i++]),
      settled_at: Number(a[i++]),
    };

    o.status = this.get_status(o.statuscode);

    o.betamt_wei = o.betamt.toString();
    o.betamt = tofeth(o.betamt_wei);
    o.winamt_wei = o.winamt.toString();
    o.winamt = tofeth(o.winamt_wei);

    o.created_at = iso(Number(o.created_at) * 1000);
    o.settled_at = iso(Number(o.settled_at) * 1000);

    return o;
  }

  async getBetInfo(betid) {
    let a = await this.contract.getBetInfo(betid);
    return this.parseBet(a);
  }

  async destroy() {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.destroy(gasinfo);
    let resp = await tx.wait();
    console.log("destroyed", resp);
    return resp;
  }

  async getOwner() {
    return await this.contract.contowner();
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);
      // console.log("par", log, par);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      if (name == "BetCreated") {
        // event BetCreated(bytes32 betid, uint created_at);
        let a = par.args;
        o.args = { betid: a[0], created_at: blocktime_iso(a[1]) };
        o.args = { marketid: a[0], created_at: blocktime_iso(a[1]) };
      } else if (name == "BetSettled") {
        // event BetSettled(bytes32 betid, uint settled_at, uint statuscode, Bet bet);
        let a = par.args;
        o.args = {
          betid: a[0],
          settled_at: blocktime_iso(a[1]),
        };
        o.args.updated_bet = this.parseBet(a[2]);
      }
      logrows.push(o);
    }
    return logrows;
  }

  async createBet(rid, hid, betamt, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.createBet(rid, hid, betamt, info);
    const resp = await tx.wait();
    return resp;
  }
}

const get_FairexMCBetsContract = async (ext = {}) => {
  const contractAddress = contractAddress_list.fairexmcbets;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new FairexMCBetsContract({
    contractAddress,
    provider,
    signer,
    ...ext,
  });
  return runner;
};

const FairexMCBets = {
  class: FairexMCBetsContract,
  get_contract: get_FairexMCBetsContract,
};

export default FairexMCBets;
