import React, { createContext, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { q_betslist, q_vaultinfo, qissuccess } from "../queries/queries.js";
import { tokdecn, useAppContext, useNowContext } from "../App.js";
import { copy_clip, dec, getv, iso, iso_format, nils } from "../utils/utils.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { Card, PolyLink, Tag, ToolTip } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { class_cn, class_text, tablecn } from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faLink,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const samp_c = (n) => {
  if (n < 20) return "text-red-400";
  if (n < 40) return "text-yellow-400";
  return "text-green-400";
};

const status_cn = (s) => {
  if (s == "win") return "text-green-400";
  if (s == "lose") return "text-red-400";
  if (s == "pending") return "text-blue-400";
  if (s == "refunded") return "text-yellow-400";
  if (s == "cancelled") return "text-yellow-400";
};

const LedgerContext = createContext({});
const useLedgerContext = () => useContext(LedgerContext);

const betstopsdelay = [-4 * 60, "seconds"];

export const BetTableRow = ({ bet: b, v, r, h, conf = {} }) => {
  return (
    <tr className={twMerge("thintdrow", "")}>
      {/* <td></td> */}
      <td>
        <div className="fr-sc resp-gap-1">
          <ToolTip message={b.betid} msg_cn="w-max resp-text--4">
            <Tag onClick={() => copy_clip(b.betid)}>
              <span className="text-purple-400 font-mon resp-text--2">
                {b.betid.slice(-5)}
              </span>
            </Tag>
          </ToolTip>

          {b.invalid == true ? (
            <ToolTip message={b.invalid_msg} msg_cn="w-max resp-text--4">
              <span className="text-red-400 font-mon resp-text--2">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </span>
            </ToolTip>
          ) : (
            <></>
          )}
        </div>
      </td>
      {conf.hide_bettor ? null : (
        <td>
          <ToolTip message={b.bettor} msg_cn="w-max resp-text--4">
            <Tag onClick={() => copy_clip(b.bettor)}>
              <span className="text-white font-mon resp-text--2">{v}</span>
            </Tag>
          </ToolTip>
        </td>
      )}

      {conf.hide_race || nils(r) ? null : (
        <td>
          <div className="fr-sc resp-gap-2 min-w-[12rem]">
            <Link
              target="_blank"
              to={`https://fbike.dnaracing.run/race/${r.rid}`}
            >
              <span className="text-white font-mon resp-text--2">
                <FontAwesomeIcon icon={faLink} />
              </span>
            </Link>

            <Link to={`/dnamc/${r.rid}`}>
              <div className="fc-ss resp-text--2">
                <span className={"text-acc0"}>{r.rid}</span>
                <span>{r.race_name}</span>
                <div className="fr-sc resp-gap-1">
                  <span className={twMerge(`text-c${r.class}`)}>
                    {class_text(r.class)}
                  </span>
                  <span className={twMerge("text-acc0")}>CB{r.cb}</span>
                </div>
                <span>BetStops:</span>
                <span>
                  {iso_format(
                    moment(r.start_time)
                      .add(...betstopsdelay)
                      .toISOString(),
                  )}
                </span>
              </div>
            </Link>
          </div>
        </td>
      )}
      <td>
        {[7501, 7502].includes(b.hid) ? (
          <div
            className={twMerge(
              "fc-ss resp-text--1",
              b.hid == 7501 ? "text-blue-400" : "text-red-300",
            )}
          >
            <span className={twMerge()}>
              {b.hid == 7501 ? "Team A" : "Team B"}
            </span>

            <span>
              {getv(
                _.keyBy(r.teamsmap, "k"),
                `${b.hid == 7501 ? "A" : "B"}.team_vault`,
              )}
            </span>
          </div>
        ) : (
          <div className="fc-ss resp-text--1">
            <span className="">#{b.hid}</span>
            <span>{h?.name ?? ""}</span>
          </div>
        )}
      </td>
      <td>
        <span className="resp-text--1 text-yellow-400">
          {dec(b.betamt, tokdecn(b.token))}
          <span className="resp-text--4">{b.token}</span>
        </span>
      </td>
      <td>
        {parseFloat(b.winamt) == 0 ? (
          <span className="resp-text--1 text-slate-400">--</span>
        ) : (
          <span className="resp-text--1 text-yellow-400">
            {dec(b.winamt, tokdecn(b.token))}
            <span className="resp-text--4">{b.token}</span>
          </span>
        )}
      </td>
      <td>
        <div className="fc-cc">
          <span
            className={twMerge(
              "resp-text--2 uppercase font-digi font-bold",
              status_cn(b.status),
            )}
          >
            {b.status}
          </span>
          {b.status == "pending" || 0 ? (
            <>
              <span className="resp-text--3">odds={dec(b.odds, 2)}</span>
              <span className="resp-text--3">
                winable={dec(b.winable, tokdecn(b.token))}
                <span className="resp-text--4">{b.token}</span>
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
      </td>
      <td>
        <div className="fr-sc resp-gap-1 resp-text--3">
          <PolyLink hash={b.created_hashid} />
          <span className="">{iso_format(b.created_at)}</span>
        </div>
      </td>
      <td>
        <div className="fr-sc resp-gap-1 resp-text--3">
          {b.status !== "pending" && (
            <>
              <PolyLink hash={b.settled_hashid} />
              <span className="">{iso_format(b.settled_at)}</span>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

const BetTableHead = ({ conf = {} }) => {
  return (
    <thead className="text-center font-digi font-extrabold thintdrow text-c2 resp-text--2">
      <tr className="thintdrow">
        {/* <td></td> */}
        <td>BetID</td>
        {conf.hide_bettor ? null : <td>Bettor</td>}
        {conf.hide_race ? null : <td>Race</td>}
        <td>BetBike</td>
        <td>Stake</td>
        <td>WinAmt</td>
        <td>Status</td>
        <td>Created At</td>
        <td>Settled At</td>
      </tr>
    </thead>
  );
};

export const BetsTable = ({ bets, vmap, rmap, hmap, conf }) => {
  if (_.isEmpty(bets))
    return (
      <p className="text-center font-digi font-extrabold thintdrow text-c2 resp-text--2 my-2">
        No Bets
      </p>
    );

  return (
    <>
      <table className={twMerge(tablecn.table, "w-full")}>
        <BetTableHead {...{ conf }} />

        <tbody>
          {bets.map((b) => {
            if (!b) return null;
            let v = vmap[b.bettor];
            let r = rmap[b.rid];
            let h = hmap[b.hid];
            return <BetTableRow {...{ key: b.betid, bet: b, v, r, h, conf }} />;
          })}
        </tbody>
      </table>
    </>
  );
};

function LedgerPage() {
  const appcon = useAppContext();
  const nowcon = useNowContext();

  const params = useParams();
  const vault = useMemo(() => {
    let v = params.vault;
    if (!v) return null;
    return v.toLowerCase();
  }, [params.vault]);

  const [page, set_page] = useState(1);
  const [limit, set_limit] = useState(25);
  const [qovdoc, qobetslist] = useQueries([
    q_vaultinfo({ vault }),
    q_betslist({ vault, page, limit }),
  ]);
  const vdoc = useMemo(
    () => getv(qovdoc, "data.result") || {},
    [qovdoc.dataUpdatedAt],
  );
  const betslist = useMemo(
    () =>
      getv(qobetslist, "data.result") || {
        bets: [],
        vmap: {},
        rmap: {},
        hmap: {},
      },
    [qobetslist.dataUpdatedAt],
  );

  const nextpage = () => set_page(page + 1);
  const prevpage = () => {
    set_page(Math.max(page - 1, 1));
  };

  const lcon = {
    vdoc,
    qobetslist,
  };

  return (
    <LedgerContext.Provider value={lcon}>
      <div className="h-page">
        <div className="w-[80rem] max-w-[98w] mx-auto">
          <div className="h-[2rem]"></div>
          <div className="fc-cc resp-gap-1">
            <p className="text-center font-digi resp-text-1 text-acc0.">Bets</p>
            <p className="text-center font-digi fr-sc resp-gap-2">
              {qovdoc.isLoading && <Loader01c size="s" />}
              <ToolTip message={vdoc.vault} msg_cn="w-max resp-text--3">
                <Tag
                  className="resp-text--1 underline underline-acc0 font-mono"
                  onClick={() => copy_clip(vault)}
                >
                  <span>{vdoc.vault_name}</span>
                </Tag>
              </ToolTip>
            </p>
          </div>
          <div className="h-[2rem]"></div>
          <Card className={"w-full overflow-auto"}>
            <div className="fr-sc resp-gap-2 resp-p-2 border-b border-acc0">
              {qobetslist.isLoading && <Loader01c size="s" />}
              <div className="flex-1"></div>
              <Tag
                onClick={prevpage}
                className={twMerge(
                  "fc-cc rounded-full bg-transparent hover:bg-lig transition duration-300",
                )}
              >
                <div className="resp-p-1">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
              </Tag>
              <span>Page - {page}</span>
              <Tag
                onClick={nextpage}
                className={twMerge(
                  "fc-cc rounded-full bg-transparent hover:bg-lig transition duration-300",
                )}
              >
                <div className="resp-p-1">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </Tag>
            </div>
            {qissuccess(qobetslist) && (
              <BetsTable
                {...{
                  ...betslist,
                }}
              />
            )}
          </Card>
        </div>
      </div>
    </LedgerContext.Provider>
  );
}

export default LedgerPage;
