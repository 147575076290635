import _ from "lodash";
import { fpost } from "./fetch.js";
import { getv, jstr, nils, tofeth } from "../utils/utils.js";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { get_auth_header } from "../wrappers/AuthWrapper.js";
import { get_mm_balance } from "./mm_fns.js";
import {
  get_weth_allowance,
  get_weth_balance,
  mm_asset_signer,
} from "../contracts/contract_funcs.js";

const usedevloc = true;

export const qiserr = (q) => {
  if (getv(q, "data.err")) return getv(q, "data.err");
  if (q.status == "error") return "server error";
  if (q.status == "loading") return false;
  return false;
};

export const iserr = (d) => {
  if (getv(d, "err") || d.status == "error") return getv(d, "data.err");
  return false;
};

export const qissuccess = (q) => {
  return getv(q, "status") == "success" && !qiserr(q);
};
export const qissuccesss = (q) => {
  return (
    getv(q, "status") == "success" &&
    getv(q, "data.status") == "success" &&
    !qiserr(q)
  );
};

export const useStepQuery = ({
  key,
  par_ar,
  q_,
  params,
  lim = 1,
  options = {},
  ...props
}) => {
  // useEffect(() => console.log("useStepQuery", par_ar), [par_ar]);

  const [enabled_n, set_enabled_n] = useState(lim);
  const qs = useQueries(
    par_ar?.map((e, idx) => {
      let ext_options = {
        enabled: idx < enabled_n,
        ...options,
      };
      // return () => {return { test: 0 }};
      return q_(...e, ext_options);
    }),
  );
  const c = useMemo(() => {
    let ob = _.countBy(qs, (e) => e.status);
    return {
      success: ob.success ?? 0,
      loading: ob.loading ?? 0,
      error: ob.error ?? 0,
      idle: ob.idle ?? 0,
    };
  }, [qs]);

  useEffect(() => {
    set_enabled_n((parseInt(c.success / lim) + 1) * lim);
  }, [c.success]);

  const datas = useMemo(() => {
    // if (qs_count.loading !== 0) return [];
    let ob = _.chain(qs).filter({ status: "success" }).map("data").value();
    // console.log("all_bikes_data", ob);
    return ob;
  }, [jstr(_.map(qs, "dataUpdatedAt")), c]);

  return {
    qs,
    datas,
    c,
    n: par_ar.length,
    done: c.success,
    tot: par_ar.length,
    loading: c.idle + c.loading > 0,
  };
};

export const btbk =
  usedevloc && process.env.NODE_ENV == "development"
    ? `http://localhost:3006`
    : `https://api.fairex.live`;

export const btbk_races = `${btbk}/fbike/races`;
export const btbk_ledger = `${btbk}/fbike/ledger`;

export const polytxnbase = btbk_ledger.includes("dev")
  ? `https://mumbai.polygonscan.com/tx`
  : `https://polygonscan.com/tx`;
export const polytxnlink = (hash) => `${polytxnbase}/${hash}`;
export const polytxnextrlink = (hash) => {
  if (nils(hash)) return null;
  hash = hash.split(":")[0];
  return `${polytxnbase}/${hash}`;
};

export const q_auth_get_nonce = async (doc) => {
  let api = `${btbk}/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_token = async (doc) => {
  let api = `${btbk}/auth/verify_token`;
  return fpost(api, doc, {});
};

export const q_auth_verify_admin = async (doc) => {
  let api = `${btbk}/auth/verify_admin`;
  return fpost(api, doc, {});
};

export const q_construct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      return fpost(api, doc);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_auconstruct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      let headers = get_auth_header();
      return fpost(api, doc, headers);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_vaultinfo = (doc, ext = {}) => {
  let o = q_construct("q_vaultinfo", `${btbk}/vault/info`, doc, ext);
  return o;
};
export const q_vault_updprofile = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_vault_updprofile",
    `${btbk}/vault/updprofile`,
    doc,
    ext,
  );
  return o;
};

export const q_token_prices = (doc, ext = {}) => {
  let next = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct("q_token_prices", `${btbk}/token_prices`, doc, next);
  return o;
};

export const q_tok_balance = (doc, ext) => {
  return {
    queryKey: ["q_tok_balance", jstr(doc)],
    queryFn: async () => {
      try {
        let { wallet, tokenaddr } = doc;
        let paycon = await mm_asset_signer(null, 20, tokenaddr);
        let bal = await paycon.balanceOf(wallet);
        // console.log({ wallet, tokenaddr, bal });
        bal = tofeth(bal);
        return { status: "success", result: { wallet, bal } };
      } catch (err) {
        return { status: "error", err: err.message };
      }
    },
    staleTime: 60 * 1000,
    ...ext,
  };
};

export const q_astokinfo = (doc, ext = {}) => {
  return q_construct("q_astokinfo", `${btbk}/fbike/dnamarket/info/i`, doc, ext);
};
export const q_astokinfo_owner = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_owner",
    `${btbk}/fbike/dnamarket/info/owner`,
    doc,
    next,
  );
};
export const q_astokinfo_owner_cacref = (doc, ext = {}) => {
  return q_construct(
    "q_astokinfo_owner",
    `${btbk}/fbike/dnamarket/info/owner_clear_cache`,
    doc,
    ext,
  );
};
export const q_astokinfo_bidslist = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_bidslist",
    `${btbk}/fbike/dnamarket/info/bidslist`,
    doc,
    next,
  );
};
export const q_astokinfo_list = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_list",
    `${btbk}/fbike/dnamarket/info/list`,
    doc,
    next,
  );
};
export const q_astokinfo_txns = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_txns",
    `${btbk}/fbike/dnamarket/info/txns`,
    doc,
    next,
  );
};
export const q_astoktxns_relayback = (doc, ext = {}) => {
  return q_construct(
    "q_astoktxns_relayback",
    `${btbk}/fbike/dnamarket/info/txn_relayback`,
    doc,
    ext,
  );
};

export const q_vaultassets = (doc, ext = {}) => {
  return q_construct(
    `q_vaultassets`,
    `${btbk}/fbike/dnamarket/vault/assets`,
    doc,
    ext,
  );
};
export const q_astoksearch = (doc, ext = {}) => {
  return q_construct(
    `q_astoksearch`,
    `${btbk}/fbike/dnamarket/search`,
    doc,
    ext,
  );
};

export const q_astoklistings = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 40 * 1e3,
    refetchInterval: 40 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astoklistings",
    `${btbk}/fbike/dnamarket/listings`,
    doc,
    ext,
  );
};

export const q_vaubidslist = (doc, ext = {}) => {
  return q_construct(
    "q_vaubidslist",
    `${btbk}/fbike/dnamarket/vault/bidslist`,
    doc,
    ext,
  );
};
export const q_upcoming_races = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_upcolming_races",
    `${btbk}/dnamc/upcoming_races`,
    doc,
    ext,
  );
};

export const q_racesim = (doc, ext = {}) => {
  let next = {
    staleTime: 3 * 60 * 1e3,
    refetchInterval: 3 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_racesim", `${btbk}/dnamc/sim`, doc, next);
};
export const q_simhinfo = (doc, ext = {}) => {
  return q_construct("q_simhinfo", `${btbk}/dnamc/hinfo`, doc, ext);
};
export const q_is_bets_eligible = (doc, ext = {}) => {
  ext = {
    staleTime: 15 * 1e3,
    refetchInterval: 15 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_is_bets_eligible",
    `${btbk}/dnamc/bets_eligible`,
    doc,
    ext,
  );
};

export const q_betslist = (doc, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct("q_betslist", `${btbk}/dnamc/betslist`, doc, ext);
};

export const q_bettor_bets_stats = (doc, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_bettor_bets_stats",
    `${btbk}/dnamc/bettor_bets_stats`,
    doc,
    ext,
  );
};

export const q_validate_bet = (doc, ext = {}) => {
  return q_construct("q_validate_bet", `${btbk}/dnamc/validate_bet`, doc, ext);
};

export const q_txnque_add = (doc, ext = {}) => {
  return q_construct("q_txnque_add", `${btbk}/dnamc/txnque_add`, doc, ext);
};

export const q_admin_bets_summary = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_auconstruct(
    "q_admin_summary",
    `${btbk}/dnamc/admin/bets_summary`,
    doc,
    ext,
  );
};

export const q_admin_bets_list = (doc, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_auconstruct(
    "q_admin_bets_list",
    `${btbk}/dnamc/admin/bets_list`,
    doc,
    ext,
  );
};

// /dnamc/leaderboard/leader
export const q_leaderboard_leader = (doc, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_leaderboard_leader",
    `${btbk}/dnamc/leaderboard/leader`,
    doc,
    ext,
  );
};

// /dnamc/leaderboard/info
export const q_leaderboard_info = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_leaderboard_info",
    `${btbk}/dnamc/leaderboard/info`,
    doc,
    ext,
  );
};

// /dnamc/leaderboard/updateinfo
export const q_leaderboard_updateinfo = (doc, ext = {}) => {
  return q_auconstruct(
    "q_leaderboard_updateinfo",
    `${btbk}/dnamc/leaderboard/updateinfo`,
    doc,
    ext,
  );
};
