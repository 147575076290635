import React, { useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useAppContext, useNowContext } from "../App.js";
import { Card, Img, InpText, Tag } from "../components/utilityComps.js";
import {
  q_astoksearch,
  q_is_bets_eligible,
  q_upcoming_races,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import _ from "lodash";
import { ElementTag } from "../components/ShortComps.js";
import { InpDropdown, set_val_inp } from "../components/input.js";
import {
  cdelay,
  from_time_mini,
  getv,
  iso,
  jstr,
  nano,
  nils,
} from "../utils/utils.js";
import { Link, useNavigate } from "react-router-dom";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import { Logo } from "../components/Layout.js";
import { class_cn, class_text } from "../utils/cn_map.js";
import moment from "moment";

const extract_rid = (link) => {
  if (nils(link)) return null;

  const regex = /\/race\/(\w+)(\?)?/;
  const match = link.match(regex);
  let m2 = null;
  if (match && match.length > 1) {
    const raceid = match[1];
    return raceid;
  } else if ((m2 = link.match(/(\w+)/))) {
    let raceid = m2[1];
    return raceid;
  }
  return null;
};

export const Search = () => {
  const history = useNavigate();
  const [searchtxt, set_searchtxt] = useState(null);
  const [popdata, set_popdata] = useState();

  useEffect(() => {
    let rid = extract_rid(searchtxt);
    console.log({ rid });
    if (nils(rid)) {
      set_popdata({
        type: "error",
        err: "Couldn't Extract RaceID",
      });
      return;
    }
    const fn = async () => {
      let exists = await q_is_bets_eligible({ rid }).queryFn();
      exists = getv(exists, "result.exists") ?? false;
      if (exists) {
        set_popdata(null);
        await cdelay(1000);
        history(`/dnamc/${rid}`);
      } else {
        set_popdata({
          type: "error",
          err: "this race is not available for fairex",
        });
      }
    };
    fn();
  }, [searchtxt]);

  return (
    <div className="xs:max-w-[90vw] lg:max-w-[45rem] w-full mx-auto relative">
      <div className="xs:fc-cc lg:fr-cc">
        <>
          <InpText
            {...{
              id: `search-fbike-core`,
              placeholder: "Enter the DNA Race link",
              inpprops: {
                className:
                  "resp-text-1 font-digi bg-slate-800/60 text-acc0 w-full",
              },
              contprops: {
                className:
                  "resp-text-1 font-digi bg-slate-800/60 text-acc0 w-full",
              },
              setter: (v) => {
                console.log("setter", v);
                if (nils(v)) v = null;
                set_searchtxt(v);
              },
            }}
          />
        </>
      </div>
      {!nils(searchtxt) && (
        <div className="fr-cc my-2">
          {popdata && popdata.type == "error" && (
            <p className="text-red-300">{popdata.err}</p>
          )}
        </div>
      )}
    </div>
  );
};

export const betstopsdelay = [-4 * 60, "seconds"];

export const RaceCard = ({ r, redirect }) => {
  const { now } = useNowContext();

  const betstops = useMemo(() => {
    return moment(r.start_time)
      .add(...betstopsdelay)
      .toISOString();
  }, [jstr(r)]);

  return (
    <div
      onClick={() => {
        if (redirect) {
          window.open(redirect, "_blank");
        }
      }}
      className="cursor-pointer"
    >
      <div className="w-full my-2 border-b border-acc0 resp-p-2">
        <div className="fr-sc my-1">
          {/* <Tag
            className={twMerge(
              "resp-text--2 font-mono"
            )}
          >
            {r.rid}
          </Tag> */}
          <Tag
            className={twMerge(class_cn(r.class), "bg-opacity-40 resp-text--1")}
          >
            {class_text(r.class)}
          </Tag>
          <Tag className={twMerge("text-acc0 resp-text--1")}>CB{r.cb}00</Tag>
          <div
            onClick={() => {
              if (!redirect) {
                window.open(
                  `https://fbike.dnaracing.run/race/${r.rid}`,
                  "_blank"
                );
              }
            }}
            className="flex-1"
          >
            <p className="resp-text-0 font-digi">{r.race_name}</p>
          </div>
        </div>
        <div className="fr-sc my-1">
          <Tag className="resp-text--2 text-acc0">{r.rgate} Gates</Tag>
          <Tag className="resp-text--2 text-acc0">{_.upperCase(r.format)}</Tag>
          <Tag className="resp-text--2 text-purple-300">
            {_.upperCase(r.payout)}
          </Tag>
          <div className="flex-1"></div>
          {r.status == "scheduled" ? (
            <>
              {now > nano(betstops) ? (
                <p className="resp-text--1 text-yellow-300"></p>
              ) : (
                <p className="resp-text--1 text-green-300">
                  {/* Starts in {from_time_mini(r.start_time)} */}
                  Bet within {from_time_mini(betstops)}
                </p>
              )}
            </>
          ) : r.status == "finished" ? (
            <>
              <p className="text-acc0">Finished</p>
            </>
          ) : (
            <p className="text-red-300">{_.capitalize(r.status)}</p>
          )}
        </div>
        {!_.isEmpty(r.event_tags) && (
          <div className="fr-sc my-1">
            {r.event_tags.map((e) => (
              <Tag className="resp-text--3 text-slate-300 underline underline-offset-2 underline-slate-400">
                {e}
              </Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const Banner = () => {
  return (
    <div className="w-full my-2">
      <Img img={"https://i.imgur.com/tQJ4VEx.png"} />
    </div>
  );
};

function HomePage() {
  const { path, history } = useAppContext();
  const aucon = useAuthContext();
  useEffect(() => {
    if (aucon.auth == true) {
    }
  }, [aucon]);

  const [qoupcoming] = useQueries([q_upcoming_races({})]);
  const races = useMemo(
    () => getv(qoupcoming, "data.result"),
    [qoupcoming.dataUpdatedAt]
  );

  return (
    <div>
      {/* <div className="h-[6rem]"></div> */}

      <div className="h-[2rem]"></div>

      {/* <p className="text-center w-[50rem] max-w-[90vw] mx-auto resp-text--1 ">
        DNA Special, we are offering 10000 dez tokens to 1 wallet that bets on
        Fairex in the next 24 hrs starting 1/9 00:01 UTC, 1 entry per eth
        wallet. We will ask someone from DNA to randomly select the winner via a
        random generator site online.
      </p> */}

      <div className="w-[50rem] max-w-[98vw] mx-auto">
        <div className="scale-[1.4] my-2">
          <Logo />
        </div>
        <Banner />

        <Search />

        <div className="my-2">
          <p className="text-center resp-text-1">
            <span>{`Need $DEZ to bet you can get it `}</span>
            <a
              className="text-acc0 underline underline-acc0"
              target="_blank"
              href="https://app.uniswap.org/tokens/polygon/0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e"
            >
              here
            </a>
          </p>
        </div>

        {qoupcoming.isLoading ? (
          <Loader01c size="s" />
        ) : qiserr(qoupcoming) ? (
          <p className="text-center resp-text--1">{qiserr(qoupcoming)}</p>
        ) : qissuccesss(qoupcoming) && _.isEmpty(races) ? (
          <p className="text-center resp-text--1 text-yellow-300">
            No Upcoming Races
          </p>
        ) : qissuccesss(qoupcoming) && !_.isEmpty(races) ? (
          <div className="">
            <div className="h-[0.2rem] max-w-[30rem] mx-auto bg-acc0/10 w-full my-2"></div>
            <div className="h-[1rem]"></div>
            {races.map((r) => (
              <RaceCard {...{ r, key: r.rid, redirect: `/dnamc/${r.rid}` }} />
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default HomePage;
