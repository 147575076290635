export const contractAddress_list = {
  weth: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  racestake: "0x60dbd31fAefa2Bf87e85e7582e389DC134eDa2b1",
  corelootboxv2: "0x20dd967458922cDC21b16F69e043c7C918bcE8eF",
  skinlootboxv1: "0xbcDEB62e65666A13b4dF9fab76258b3164Da18bd",
  fbikeskin: "0xCD0783C0e2b0A68A64ba7c5e0F99097945397cF3",
  splicing: "0x792a3A3539A314f4FaC63F6fcc546c3ef9fFC8CE",
  racestakev02: "0x1DfE57D9aC38E3168ab920f050934667d32Ba433",
  dnamarket: "0x18bea2aef6a3fef912fe7458f527d6dfbe5e98ab",
  fairexmcbets: "0xa55e807f8466634562e9777965bb12561e117c9e",
  dez: "0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e",
};

export const contractABIs = {};
