import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { Loader01c } from "../components/anims.js";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import { useMatch, useParams } from "react-router";
import { get_inp, getv, jstr, nils, set_inp } from "../utils/utils.js";
import { useQueries } from "react-query";
import { q_vault_updprofile, q_vaultinfo } from "../queries/queries.js";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import "intersection-observer";
import { Link } from "react-router-dom";

export const VaultContext = createContext({});
export const useVaultContext = () => useContext(VaultContext);

function Vault() {
  const aucon = useAuthContext();
  const { vault } = useParams();

  const [qo_vaultinfo] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const [editmode, set_editmode] = useState(false);
  const [editing, set_editing] = useState(false);

  const is_my_vault = useMemo(() => {
    // return true;
    let a = aucon?.vault?.toLowerCase();
    let b = vault?.toLowerCase();
    return !nils(a) && a === b;
  }, [aucon.vault, vault]);

  const apply_updprofile = () => {
    set_editing(true);
    let id = "inp_vault_name";
    let upd = {};
    upd.vault_name = get_inp(id);
    if (nils(upd.vault_name)) {
      set_editing(false);
    } else {
      upd.vault_name = upd.vault_name.trim();
      q_vault_updprofile({ upd })
        .queryFn()
        .then(() => {
          setTimeout(() => {
            qo_vaultinfo.refetch();
          }, 1000);

          setTimeout(() => {
            set_inp(id, "");
            set_editmode(false);
            set_editing(false);
          }, 3000);
        });
    }
  };

  const vcon = {
    qo_vaultinfo,

    editmode,
    set_editmode,
  };

  const pagetitle = useMemo(() => {
    if (nils(vdoc)) return `Vault ${vault} | FairEx`;
    let rn = vdoc.vault_name;
    return `'${rn}' Vault | FairEx`;
  }, [vdoc]);

  return (
    <div className="">
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[5rem]"></div>
          <VaultContext.Provider value={vcon}>
            {_.isEmpty(vdoc) ? (
              <Loader01c />
            ) : (
              <>
                <Card
                  className={"xs:w-[90%] lg:w-[50rem] mx-auto overflow-auto"}
                >
                  <motion.div
                    variants={MoVariants.show_hide}
                    animate={editmode == false ? "visible" : "hidden"}
                  >
                    <div className="fc-cs font-digi resp-text-1 flex-1">
                      <div className="fr-sc resp-gap-2 flex-wrap">
                        <span className="resp-text--1"> Name: </span>
                        <span className="text-acc0 resp-text--1">
                          {vdoc.vault_name}
                        </span>
                      </div>
                      <div className="fr-sc resp-gap-2 flex-wrap">
                        <span className="resp-text--1"> Address: </span>
                        <span className="text-acc0 xs:text-[7px] lg:text-[0.8rem] whitespace-break-spaces">
                          <span className="xs:hidden lg:block">{vault}</span>
                          <span className="xs:block lg:hidden">
                            0x...{vault.slice(0, 5)}
                          </span>
                        </span>
                      </div>
                    </div>
                  </motion.div>
                  {/* {is_my_vault === true && (
                    <>
                      <hr className="resp-my-2" />

                      <motion.div
                        variants={MoVariants.show_hide}
                        animate={editmode == true ? "visible" : "hidden"}
                      >
                        <div className="fr-cc resp-gap-2 resp-text--2">
                          <span className="font-digi">Account Name:</span>
                          <InpText
                            {...{
                              id: "inp_vault_name",
                              setter: (v) => {},
                              inpprops: {
                                className: "xs:w-[10rem] lg:w-[20rem]",
                              },
                            }}
                          />
                        </div>
                      </motion.div>

                      <div className="fr-sc resp-gap-2">
                        <div className="flex-1"></div>

                        {editmode && (
                          <Tag
                            onClick={apply_updprofile}
                            className={twMerge(
                              "fr-cc resp-gap-2",
                              editing
                                ? "bg-dark text-acc0"
                                : "bg-green-300 text-black"
                            )}
                          >
                            {editing && <Loader01c size="s" />}
                            <span>Apply Changes</span>
                          </Tag>
                        )}
                        <Tag
                          onClick={() => {
                            set_editmode(!editmode);
                          }}
                          className={twMerge(
                            "text-black",
                            editmode ? "bg-red-300" : "bg-yellow-300"
                          )}
                        >
                          {editmode ? "Discard" : "Edit Profile"}
                        </Tag>
                      </div>
                    </>
                  )} */}
                </Card>
              </>
            )}
          </VaultContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default Vault;
