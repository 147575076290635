import React, {
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { useNowContext } from "../App";
import { Loader01c } from "../components/anims";
import { F_DateInput, F_Input, F_OptionInput } from "../components/input";
import { PageWrapper } from "../components/ShortComps";
import { Card, Tag } from "../components/utilityComps";
import {
  qissuccesss,
  q_leaderboard_info,
  q_leaderboard_updateinfo,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { cdelay, getv, jstr } from "../utils/utils";

export const LeaderBoardContext = createContext({});
export const useLeaderBoardContext = () => useContext(LeaderBoardContext);

export const AdminLeaderBoard = () => {
  const [qi] = useQueries([q_leaderboard_info()]);
  const i = useMemo(() => getv(qi, "data.result"), [qi.dataUpdatedAt]);
  const { now } = useNowContext();
  const [doc, set_doc] = useState(undefined);
  useEffect(() => {
    if (qissuccesss(qi) && doc == undefined) {
      set_doc(getv(qi, "data.result"));
    }
  }, [qi.dataUpdatedAt]);

  const inpargs = {
    nf: doc,
    set_nf: set_doc,
    nfkey: "ad_leaderinfo",
  };

  const [resp, set_resp] = useState({});
  const reset = () => {
    let i = getv(qi, "data.result");
    set_doc(i);
  };
  const update = async () => {
    try {
      set_resp({ loading: true });
      let re = await q_leaderboard_updateinfo({ doc }).queryFn();
      console.log(re);
      await cdelay(1000);
      await qi.refetch();
      set_resp(re);
    } catch (err) {
      console.log(err);
      set_resp({ err: err.message });
    }
  };

  return (
    <PageWrapper page_title_fn={() => `Admin Leaderboard`}>
      <div class="resp-text-2 font-digi text-center">Admin Leaderboard</div>
      {qi.isLoading && <Loader01c />}
      <div class="fc-cc resp-gap-2">
        <div class="w-full">
          <Card className="w-full resp-text-0 fr-sc bg-reg">
            <span className="resp-text--1">{jstr(resp)}</span>
            <div class="flex-1"></div>
            <Tag className="bg-orange-500/40" onClick={reset}>
              <span class="resp-text--1">Reset</span>
            </Tag>
            <Tag className="bg-green-500/40" onClick={update}>
              <span class="resp-text--1">Save</span>
            </Tag>
          </Card>
        </div>
        <div class="w-full min-h-[4rem] resp-text-0">
          <table
            className={twMerge(tablecn.table, "w-full thintdrowp4-table-acc0")}
          >
            <tr>
              <td>Name</td>
              <td>
                <div class="w-full">
                  <F_Input {...inpargs} path="name" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Start Date</td>
              <td>
                <div class="w-full">
                  <F_DateInput {...inpargs} path="st" />
                </div>
              </td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>
                <div class="w-full">
                  <F_DateInput {...inpargs} path="ed" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <div class="w-full">
                  <F_OptionInput
                    {...inpargs}
                    type="option"
                    path="status"
                    options={["yettostart", "running", "ended"]}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Delete Leaderboard</td>
              <td>
                <div class="w-full">
                  <F_OptionInput
                    {...inpargs}
                    type="option"
                    path="del_leader"
                    txt_fn={(o) =>
                      o === true ? "Delete" : o === false ? "Keep" : "---"
                    }
                    options={[true, false, null]}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Hold Running</td>
              <td>
                <div class="w-full">
                  <F_OptionInput
                    {...inpargs}
                    type="option"
                    path="hold"
                    txt_fn={(o) => (o === true ? "Hold" : "Keep running")}
                    options={[true, null]}
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </PageWrapper>
  );
};

export default AdminLeaderBoard;
