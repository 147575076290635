import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import {
  faBackspace,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faDollar,
  faFire,
  faHorse,
  faPencil,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { Loader01c } from "../components/anims.js";
import {
  InpDropdown,
  extract_inp,
  set_state_ob,
  set_val_inp,
} from "../components/input.js";
import {
  BY_Star,
  Card,
  ITag,
  Img,
  InpText,
  PosTag,
  Tag,
  ToolTip,
} from "../components/utilityComps.js";
import {
  polytxnextrlink,
  polytxnlink,
  q_betslist,
  q_bettor_bets_stats,
  q_racedoc,
  q_racesim,
  q_simhinfo,
  q_txnque_add,
  q_validate_bet,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { class_cn, class_text, elementmap, tablecn } from "../utils/cn_map.js";
import {
  cdelay,
  dec,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  toeth,
  tofeth,
} from "../utils/utils.js";
import { tokdecn, useAppContext, useNowContext } from "../App.js";
import { bikepage, polychainimg, vaultpage } from "../utils/links.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ElementTag, FNoTag } from "../components/ShortComps.js";
import { useParams } from "react-router";
import { RaceCard, betstopsdelay } from "./HomePage.js";
import { Link } from "react-router-dom";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import FairexMCBets from "../contracts/FairexMCBets/FairexMCBets.js";
import { get_weth_balance, mm_asset_signer } from "../contracts/contract_funcs";
import { contractAddress_list } from "../contracts/constants.js";
import {
  bet_paytoken_addr,
  bet_token,
  useAccountContext,
} from "../wrappers/AccountWrapper.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { BetsTable } from "./LedgerPage.js";
import moment from "moment";

const MC_Context = createContext();
const useMC_Context = () => useContext(MC_Context);

const BetBtn = ({ hid, betstops }) => {
  const { now } = useNowContext();

  const mcon = useMC_Context();
  const { rid, hsob, simsdata: s } = mcon;

  const { vault } = useAccountContext();

  const hinfo = useMemo(() => {
    let h = getv(hsob, hid) ?? {};
    let h1 = _.find(s, { hid }) ?? {};
    let hinfo = { ...h, ...h1 };
    return hinfo;
  }, [hid, jstr(s), jstr(hsob)]);

  const [pop, set_pop] = useState(false);
  const [popdata, set_popdata] = useState({});
  const [popresp, set_popresp] = useState({});
  const [popstage, set_popstage] = useState(0);

  // useEffect(() => {
  //   console.log("pop", { pop, popstage, popdata, popresp });
  // }, [pop, popstage, jstr(popdata), jstr(popresp)]);

  const place_bet = async (rid, hid, betamt) => {
    try {
      const con = await FairexMCBets.get_contract();

      let betamt_wei = toeth(betamt);
      const tx = await con.createBet(rid, hid, betamt_wei);
      console.log("placebet", tx);
      if (tx?.hash) return tx.hash;
    } catch (err) {
      console.log(err);
      if (err?.reason) throw new Error(`Chain: ${err.reason}`);
      else throw new Error(err.message);
    }
  };
  const auto_place_bet = async () => {
    try {
      set_popresp({ loading: true, status: "msg", msg: "Placing Bet..." });

      let { betamt } = popdata;
      betamt = parseFloat(betamt);
      if (nils(betamt)) throw new Error("invalid betamt");
      betamt = parseFloat(dec(betamt, 4));

      set_popresp({ loading: true, status: "msg", msg: "Validating Bet..." });
      let valresp = await q_validate_bet({
        rid,
        hid,
        betamt,
        bettor: vault,
      }).queryFn();
      if (valresp.err) throw new Error(valresp.err);

      set_popresp({ loading: true, status: "msg", msg: "Fetching Balance..." });

      let paycon = await mm_asset_signer(null, 20, bet_paytoken_addr);

      let bal = await paycon.balanceOf(vault);
      bal = parseFloat(tofeth(bal));
      if (bal < betamt) throw new Error("insufficient balance");

      await cdelay(1000);
      set_popresp({
        loading: true,
        status: "msg",
        msg: "Fetching Allowance...",
      });

      let alw = await paycon.allowance(
        vault,
        contractAddress_list.fairexmcbets
      );
      alw = parseFloat(tofeth(alw));
      if (betamt > alw) {
        await cdelay(1000);
        set_popresp({
          loading: true,
          status: "msg",
          msg: "Please confirm Metamask to add WETH allowance...",
        });

        let aprv = await paycon.approve(
          contractAddress_list.fairexmcbets,
          toeth(betamt)
        );
        await aprv.wait();
      }

      await cdelay(1000);
      set_popresp({
        loading: true,
        status: "msg",
        msg: "Confirm Metamask to place bet...",
      });
      let txhash = await place_bet(rid, hid, betamt);

      let quedoc = {
        hash: txhash,
        rid,
        hid,
        betamt,
        bettor: vault,
      };
      await q_txnque_add(quedoc).queryFn();

      // todo: relay back hash to db

      if (!nils(txhash)) {
        set_popresp({
          ...popresp,
          status: "msg",
          msg: `Bet Placed sucessfully... Txn should show up in a few minutes`,
        });
        await cdelay(5000);
        closepopup();
      }
    } catch (err) {
      set_popresp({ ...popresp, status: "error", msg: err.message });
      await cdelay(5000);
      set_popresp({ loading: false });
    }
  };

  const closepopup = async () => {
    try {
      set_popresp({});
      set_popdata();
      set_pop(false);
      set_popstage(0);
    } catch (err) {}
  };

  useEffect(() => {
    if (now > nano(betstops)) closepopup();
  }, [betstops, now]);

  const calc_winnable = (betamt, odds) => {
    betamt = parseFloat(betamt);
    odds = parseFloat(odds);
    if (nils(betamt) || nils(odds)) return null;
    return betamt * (odds + 1) * 0.95;
  };

  const [qo_bst] = useQueries([
    q_bettor_bets_stats({ bettor: vault, rid, hid }),
  ]);
  const bst = useMemo(
    () => getv(qo_bst, "data.result") ?? {},
    [qo_bst.dataUpdatedAt]
  );

  return (
    <>
      <Tag
        onClick={() => set_pop(true)}
        className="border border-acc0 font-digi resp-text--1"
      >
        BET
      </Tag>
      <PopUp openstate={pop} onClose={closepopup}>
        <Card className={twMerge("max-w-[98vw] w-[40rem]")}>
          <div className="fr-cs my-2">
            {popstage !== 0 && (
              <Tag
                onClick={() => {
                  set_popstage(Math.max(0, popstage - 1));
                }}
                className="text-acc0 border border-acc0 -skew-x-12 fr-sc resp-gap-2 resp-text--2"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>Back</span>
              </Tag>
            )}

            <div className="flex-1"></div>
            <PopupCloseBtn closepopup={closepopup} />
          </div>

          {!_.isEmpty(popresp) && (
            <div
              className={twMerge(
                "fr-sc resp-gap-2 resp-p-2 border rounded-md",
                popresp.status == "error"
                  ? "border-red-400 text-red-400"
                  : popresp.status == "msg"
                  ? "border-green-400 text-green-400"
                  : ""
              )}
            >
              {popresp.loading && <Loader01c size="s" />}
              <p>{popresp.msg}</p>
            </div>
          )}

          {popstage == 0 && (
            <>
              <div className="fc-cc">
                <div className="flex-1">
                  <div className="fr-sc my-2 resp-gap-2">
                    <div className="w-max">
                      <InpText
                        {...{
                          id: "inp-betamt",
                          label: "Bet Amount",
                          defval: getv(popdata, "betamt"),
                          contprops: { className: "bg-dark" },
                          inpprops: { className: "w-full" },
                          setter: (v) => {
                            v = parseFloat(v);
                            if (nils(v)) v = null;
                            else {
                            }
                            set_popdata({ ...popdata, betamt: v });
                          },
                        }}
                      />
                    </div>
                    <span className="resp-text--1 text-acc0">{bet_token}</span>
                  </div>
                </div>

                <div className="">
                  {qo_bst.isLoading ? (
                    <Loader01c size="s" />
                  ) : qissuccesss(qo_bst) ? (
                    <div className="resp-text--2">
                      <p className="my-2">For this Race and Bike</p>
                      <table className={tablecn.table}>
                        <tbody>
                          <tr className={"thintdrowp4"}>
                            <td>In Action</td>
                            <td>
                              <p className="text-right">{dec(bst.inbet)} DEZ</p>
                            </td>
                          </tr>
                          <tr className={"thintdrowp4"}>
                            <td>Max Allowed</td>
                            <td>
                              <p className="text-right">
                                {dec(bst.maxbet)} DEZ
                              </p>
                            </td>
                          </tr>
                          <tr className={"thintdrowp4"}>
                            <td>Remaining Allowed</td>
                            <td>
                              <p className="text-right">
                                {dec(bst.rembet)} DEZ
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="fr-sc my-2">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => {
                    set_popstage(popstage + 1);
                  }}
                  className="text-acc0 border border-acc0 -skew-x-12 fr-sc resp-gap-1 resp-text--2"
                >
                  <span>Next</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Tag>
              </div>
            </>
          )}

          {popstage == 1 && (
            <>
              <div className="fr-sc my-2">
                <Card className={twMerge("resp-p-2 w-full bg-dark")}>
                  <p className="text-yellow-300 text-center resp-my-4">
                    Please confirm these details before confirming
                  </p>
                  <p>
                    <span className="">RaceID:</span>
                    <span className="text-acc0">{` ${rid}`}</span>
                  </p>
                  {!nils(hinfo.k) ? (
                    <>
                      <p>
                        <span className="">Team:</span>
                        <span
                          className={twMerge(
                            hinfo.k == "A" ? "text-blue-400" : "text-red-400"
                          )}
                        >{` ${hinfo.k}`}</span>
                      </p>
                      <p>
                        <span className="">Team Vault:</span>
                        <span
                          className={twMerge(
                            hinfo.k == "A" ? "text-blue-400" : "text-red-400"
                          )}
                        >{` ${hinfo.team_vault}`}</span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="">BikeID:</span>
                        <span className="text-acc0">{` ${hid}`}</span>
                      </p>
                      <p>
                        <span className="">BikeName:</span>
                        <span className="text-acc0">{` ${hinfo.name}`}</span>
                      </p>
                    </>
                  )}
                  <p>
                    <span className="">Est.Odds:</span>
                    <span className="text-acc0">{` ${hinfo.odds}`}</span>
                  </p>
                  <p>
                    <span className="">BET amt:</span>
                    <span className="text-acc0">{` ${dec(
                      popdata.betamt,
                      tokdecn(bet_token)
                    )} ${bet_token}`}</span>
                  </p>
                  <p>
                    <span className="">Winnable Amt:</span>
                    <span className="text-green-300">{` ${dec(
                      calc_winnable(popdata.betamt, hinfo.odds),
                      tokdecn(bet_token)
                    )} ${bet_token}`}</span>
                  </p>
                </Card>
              </div>
              <div className="fr-cc my-2">
                {popresp.loading ? (
                  <div className="fr-sc resp-gap-1">
                    <Loader01c size="s" />
                    <span className="text-acc0">placing your bet...</span>
                  </div>
                ) : (
                  <Tag
                    className={twMerge(
                      "font-digi text-white resp-text-0 bg-acc0/40"
                    )}
                    onClick={auto_place_bet}
                  >
                    Confirm Bet
                  </Tag>
                )}
              </div>
            </>
          )}
        </Card>
      </PopUp>
    </>
  );
};

const MCResult = () => {
  const mccon = useMC_Context();
  const { now } = useNowContext();
  const { rid, race: r, simsdata: s, hsob } = mccon;

  const is_bet_eligible = useMemo(() => {
    return true;
  }, []);

  const betstops = useMemo(() => {
    return moment(r.start_time)
      .add(...betstopsdelay)
      .toISOString();
  }, [jstr(r)]);

  return (
    <>
      <RaceCard r={r} />
      <Card className={"w-full"}>
        <div className="w-full">
          <table className={tablecn.table}>
            <thead className="text-center thintdrow text-acc0 resp-text--2">
              <th>Gate</th>
              <th>BikeID</th>
              <th>Name</th>
              <th>Details</th>
              <th>Star</th>
              <th>Odds</th>
            </thead>
            <tbody className="thintdrow resp-text--2">
              {s.map((h, i) => {
                let hid = h.hid;
                const hrow_isteam = !nils(h.k);
                const hd = hrow_isteam
                  ? _.find(r.teamsmap, (e) => e.k == h.k)
                  : hsob[hid];
                // console.log(h.hid, h, hd);
                if (!hd) return <></>;
                return (
                  <tr className="">
                    {hrow_isteam ? (
                      <>
                        <td colSpan={2}>
                          <Tag
                            className={twMerge(
                              h.k == "A" ? "text-blue-400" : "text-red-400"
                            )}
                          >
                            <span className="resp-text--2 font-digi">
                              {`Team ${h.k}`}
                            </span>
                          </Tag>
                        </td>
                        <td colSpan={2}>
                          <div
                            className={twMerge(
                              "fc-ss resp-gap-1 resp-text--2",
                              h.k == "A" ? "text-blue-400" : "text-red-400"
                            )}
                          >
                            <p className="resp-text--2">{h.team_vault}</p>
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <div className="flex flex-row">
                            <Tag className="resp-text--2 text-white font-digi">
                              Gate-{h.gate}
                            </Tag>
                          </div>
                        </td>
                        <td>
                          <Link target="_blank" to={bikepage(h.hid)}>
                            <Tag className={`text-acc0 border-acc0 font-digi`}>
                              {h.hid}
                            </Tag>
                          </Link>
                        </td>
                        <td>
                          <div className="flex flex-col justify-start items-start">
                            <Link target="_blank" to={bikepage(h.hid)}>
                              <p>{hd.name}</p>
                            </Link>
                            <a
                              href={vaultpage(hd.vault)}
                              target="_blank"
                              className="underline text-yellow-400 text-[10px]"
                            >
                              {hd.vault_name}
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="fr-ss font-mon gap-2 uppercase resp-text--2">
                            <div className="flex-1"></div>
                            <span className="font-digi text-acc0">
                              F{hd.fno}
                            </span>
                            <span>{_.capitalize(hd.type?.slice(0, 4))}</span>
                            <span className={elementmap[hd.element]?.text}>
                              <FontAwesomeIcon
                                icon={elementmap[hd.element]?.icon}
                              />
                            </span>
                          </div>
                        </td>
                      </>
                    )}
                    <td className="font-mono text-center">
                      <BY_Star star={h.star} />
                    </td>
                    <td className="font-mono">
                      <span>{dec(h.odds, 2)}</span>
                    </td>
                    {is_bet_eligible === true && now < nano(betstops) ? (
                      <>
                        <td>
                          <BetBtn {...{ hid, betstops }} />
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};

const BetsList = () => {
  const mccon = useMC_Context();
  const { rid, hsob } = mccon;
  const aucon = useAuthContext();
  const { vault } = useAccountContext();

  const [qobetslist] = useQueries([
    q_betslist(
      {
        vault,
        rid,
      },
      { enabled: !nils(vault) && aucon.auth == true }
    ),
  ]);
  const { bets, vmap, rmap, hmap } = useMemo(() => {
    let d = getv(qobetslist, "data.result") || {
      bets: [],
      vmap: {},
      rmap: {},
      hmap: {},
    };
    return d;
  }, [qobetslist.dataUpdatedAt]);

  return (
    <div className="my-2">
      <div className="h-[1rem]"></div>
      {!(vault && aucon.auth == true) ? (
        <>
          <Card>
            <div className="fr-sc resp-gap-2">
              <span>Please</span>
              <span className="text-purple-400">Login</span>
              <span>to view your Bets</span>
            </div>
          </Card>
        </>
      ) : (
        <>
          <p className="text-left text-acc0 font-digi resp-text-1">Bets List</p>
          {qobetslist.isLoading ? (
            <Loader01c size="s" />
          ) : qiserr(qobetslist) ? (
            <p className="text-center text-red-300">{qiserr(qobetslist)}</p>
          ) : qissuccesss(qobetslist) && _.isEmpty(bets) ? (
            <p className="text-center text-yellow-300">No Bets Placed Yet</p>
          ) : qissuccesss(qobetslist) && !_.isEmpty(bets) ? (
            <Card className={"w-full"}>
              <BetsTable
                {...{
                  bets,
                  vmap,
                  rmap,
                  hmap,
                  conf: {
                    hide_bettor: true,
                    hide_race: true,
                  },
                }}
              />
            </Card>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export const MC_Page = () => {
  const { rid } = useParams();
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  // const [rid, set_rid] = useState(psearch.rid ?? null);
  const [qosim] = useQueries([q_racesim({ rid }, { enabled: !nils(rid) })]);
  // useEffect(() => {
  //   let vals = {};
  //   vals.rid = rid;
  //   upd_psearch(vals);
  // }, [jstr({ rid })]);

  const [race, simsdata] = useMemo(() => {
    if (!qissuccesss(qosim)) return [null, null];
    let race = getv(qosim, "data.result.race");
    let simsdata = getv(qosim, "data.result.simsdata");
    return [race, simsdata];
  }, [qosim.dataUpdatedAt]);

  const hids = useMemo(() => {
    return _.map(simsdata, "hid");
  }, [jstr(simsdata)]);

  const qshs = useQueries(
    hids?.map((hid) => q_simhinfo({ hid }, { enabled: !nils(hid) }))
  );
  const hsob = useMemo(() => {
    return _.chain(qshs)
      .map((e, i) => {
        let hid = hids[i];
        if (qissuccesss(e)) return [hid, getv(e, "data.result")];
        return [hid, null];
      })
      .fromPairs()
      .value();
  }, [jstr(qshs.map((e) => e.dataUpdatedAt))]);

  const mccon = {
    rid,
    race,
    simsdata,
    hsob,
  };
  return (
    <MC_Context.Provider value={mccon}>
      <div className="h-[5rem]"></div>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          {qosim.isLoading ? (
            <Loader01c />
          ) : qiserr(qosim) ? (
            <p className="text-center text-red-300">{qiserr(qosim)}</p>
          ) : qissuccesss(qosim) ? (
            <MCResult />
          ) : (
            <></>
          )}
          <BetsList />
        </div>
      </div>
    </MC_Context.Provider>
  );
};
