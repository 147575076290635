import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../constants.js";

class WETH_MockTokenC {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async mintTokens(account, amount) {
    const tx = await this.contract.mint(account, amount);
    await tx.wait();
    console.log(`${amount} tokens minted for ${account}`);
  }

  async getBalance(account) {
    const balance = await this.contract.balanceOf(account);
    console.log(`Balance of ${account}: ${balance.toString()} tokens`);
    return balance;
  }

  async getMyBalance() {
    let addr = window.ethereum.selectedAddress;
    const balance = await this.contract.balanceOf(addr);
    return balance;
  }

  async approve(spender, amount) {
    console.log("approve calling", { spender, amount });
    const approveTx = await this.contract.approve(spender, amount);
    await approveTx.wait();
    // console.log(`Approved ${amount} tokens for ${spender}`);
  }

  async allowance(wallet, spender) {
    try {
      // const wallet = await this.signer.getAddress();
      const allowance = await this.contract.allowance(wallet, spender);
      // console.log(`Allowance of ${wallet} for ${spender}: ${allowance} tokens`);
      return allowance;
    } catch (error) {
      console.log("Error getting allowance:", error);
      return 0; // Default to 0 in case of an error
    }
  }

  async getAllowance(wallet, spender) {
    try {
      // const wallet = await this.signer.getAddress();
      const allowance = await this.contract.allowance(wallet, spender);
      // console.log(`Allowance of ${wallet} for ${spender}: ${allowance} tokens`);
      return allowance;
    } catch (error) {
      console.log("Error getting allowance:", error);
      return 0; // Default to 0 in case of an error
    }
  }

  /*   async destroyContract() {
    const tx = await this.contract.destroy();
    await tx.wait();
    console.log("Contract destroyed");
  } */
}

const get_WETH_MockToken = async () => {
  const contractAddress = contractAddress_list.weth;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new WETH_MockTokenC({ contractAddress, provider, signer });
  return runner;
};

const WETH_MockToken = {
  class: WETH_MockTokenC,
  get_contract: get_WETH_MockToken,
};
export default WETH_MockToken;
