import React, { useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useQueries } from "react-query";
import {
  q_admin_bets_list,
  q_admin_bets_summary,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import {
  base64_to_json,
  dec,
  getv,
  iso,
  iso_format,
  json_to_base64,
  jstr,
  nils,
  tofeth,
} from "../utils/utils.js";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import { tablecn } from "../utils/cn_map.js";
import { Card, Tag, TokenIcon } from "../components/utilityComps.js";
import _ from "lodash";
import { BetsTable } from "./LedgerPage.js";
import { Loader01c } from "../components/anims.js";
import { bet_token } from "../wrappers/AccountWrapper.js";
import { InpAutoWrap } from "../components/input.js";
import { gen_valob_from_filters } from "../utils/filt";
import { useAppContext } from "../App.js";
import FairexMCBets from "../contracts/FairexMCBets/FairexMCBets.js";
import { contractAddress_list } from "../contracts/constants.js";
import { mm_asset_signer } from "../contracts/contract_funcs.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const mainfiltbtn =
  "transition duration-500 text-[0.8rem] font-digi font-semibold  text-center mb-3 ";

export const AdminWrapper = ({ children }) => {
  const aucon = useAuthContext();
  if (aucon.auth_admin !== true) return <></>;

  return <>{children}</>;
};

const AdLedgerContext = React.createContext({});
export const useAdLedgerContext = () => React.useContext(AdLedgerContext);

const FiltSection = () => {
  const lcon = useAdLedgerContext();
  const { filt, set_filt } = lcon;
  const inpargs = {
    filters: filt,
    set_filters: set_filt,
    fkey: "bets",
  };
  // console.log("filt", filt)

  return (
    <div className="w-full">
      <Card className={twMerge("my-2 w-full")}>
        <InpAutoWrap {...{ ...inpargs, idd: "vault" }} />
        <InpAutoWrap {...{ ...inpargs, idd: "dates" }} />
      </Card>
    </div>
  );
};

const BetsTableSection = () => {
  const lcon = useAdLedgerContext();
  const { qo_bets, betslist } = lcon;
  return (
    <Card className={twMerge("w-full overflow-auto")}>
      {qo_bets.isLoading ? (
        <Loader01c size="s" />
      ) : qiserr(qo_bets) ? (
        <p className="text-red-400">Error:{qiserr(qo_bets)}</p>
      ) : qissuccesss(qo_bets) ? (
        <BetsTable {...{ ...betslist }} />
      ) : (
        <></>
      )}
    </Card>
  );
};

const Funds = () => {
  const mmcon = useMetaMaskContext();
  const contract_title = "FairexMCBets - Funds";
  const token = "DEZ";
  const tokdecn = 0;
  const tokaddress = contractAddress_list.dez;

  const [bal, set_bal] = useState(undefined);
  const [balloading, set_balloading] = useState(false);
  const update_funds = async () => {
    set_balloading(true);
    try {
      const conaddr = await contractAddress_list.fairexmcbets;
      let tokcon = await mm_asset_signer(null, 20, tokaddress);
      let bal_wei = await tokcon.balanceOf(conaddr);
      let bal = tofeth(bal_wei);
      set_bal(bal);
    } catch (err) {
      console.log(err);
    }
    set_balloading(false);
  };

  return (
    <div className="w-full">
      <p className="text-acc0 text-center resp-text-1 my-2">{contract_title}</p>
      <div className="fr-cc w-full resp-gap-2 font-digi resp-text-1">
        <TokenIcon token={token} />
        {nils(bal) ? <span>--</span> : <span>{dec(bal, tokdecn)}</span>}
        {balloading ? (
          <Loader01c size="s" />
        ) : (
          <Tag onClick={update_funds}>
            <FontAwesomeIcon icon={faSync} />
          </Tag>
        )}
      </div>
    </div>
  );
};

const Summary = ({ s }) => {
  const lcon = useAdLedgerContext();
  const { all_sum, filt_sum } = lcon;
  return (
    <Card className="w-full overflow-auto">
      <Funds />

      <table className={twMerge(tablecn.table, "mx-auto resp-text--2")}>
        <thead>
          <tr className={twMerge("thintdrowp4", "text-c2 font-digi")}>
            <td>Info</td>
            <td>.</td>
            <td>Open.Funds</td>
            <td>IN</td>
            <td>OUT</td>
            <td>Profit</td>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(all_sum) && (
            <tr className={twMerge("thintdrowp4", "resp-text--2")}>
              <td>All</td>
              <td></td>
              <td>{dec(all_sum?.open_funds, 0)}</td>
              <td>{dec(all_sum?.funds_in, 0)}</td>
              <td>{dec(all_sum?.funds_out, 0)}</td>
              <td>
                <div className="text-center text-green-400 resp-text--1 fr-cc resp-gap-1 font-digi">
                  <TokenIcon token={bet_token} />
                  <span>{dec(all_sum?.profit, 0)}</span>
                </div>
              </td>
            </tr>
          )}
          {!_.isEmpty(filt_sum) && (
            <tr className={twMerge("thintdrowp4", "resp-text--2")}>
              <td>
                <div className="fc-ss">
                  <p>Filtered</p>
                  <p className="resp-text--4">{iso_format(filt_sum.st)}</p>
                  <p className="resp-text--4">{iso_format(filt_sum.ed)}</p>
                  {!nils(filt_sum.bettor) && (
                    <>
                      <p className="resp-text--4">-</p>
                      <p className="resp-text--3 text-acc0">
                        {filt_sum.bettor_name}
                      </p>
                    </>
                  )}
                </div>
              </td>
              <td>
                <div className="fc-ss"></div>
              </td>
              <td>{dec(filt_sum?.open_funds, 0)}</td>
              <td>{dec(filt_sum?.funds_in, 0)}</td>
              <td>{dec(filt_sum?.funds_out, 0)}</td>
              <td>
                <div className="text-center text-green-400 resp-text--1 fr-cc resp-gap-1 font-digi">
                  <TokenIcon token={bet_token} />
                  <span>{dec(filt_sum?.profit, 0)}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
};

function AdminLedger() {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const basefilt = useMemo(() => {
    if (nils(psearch.f)) return {};
    return base64_to_json(psearch.f) ?? {};
  }, [jstr(psearch)]);

  const [filt, set_filt] = useState({
    vault: {
      type: "inputtext",
      label: "Vault",
      show_label: true,
      label_cn: "bg-transparent text-c2 font-digi",
      cn: "bg-trasparent text-acc0 w-full",
      border_cn: "w-[19rem] bg-dark border border-acc0",
      vals: basefilt.bettor ?? "",
    },
    dates: {
      type: "date-range",
      label: "Dates Range",
      show_label: true,
      label_cn: "bg-transparent text-c2 font-digi",
      cn: "bg-trasparent text-acc0",
      border_cn: "bg-dark border border-acc0",
      show_label: true,
      vals: {
        mi: basefilt.st ?? iso().slice(0, 10) + "T00:00:00.000Z",
      },
    },
  });

  const valfilt = useMemo(() => {
    let vv = gen_valob_from_filters(filt);
    let v = {
      st: getv(vv, "dates.mi"),
      ed: getv(vv, "dates.mx"),
      bettor: getv(vv, "vault"),
    };
    return v;
  }, [jstr(filt)]);
  useEffect(() => {
    upd_psearch({ f: json_to_base64(valfilt) });
  }, [jstr(valfilt)]);

  const [qo_all_sum, qo_filt_sum, qo_bets] = useQueries([
    q_admin_bets_summary({}),
    q_admin_bets_summary(valfilt, {}),
    q_admin_bets_list(valfilt, {}),
  ]);

  const all_sum = useMemo(
    () => getv(qo_all_sum, "data.result"),
    [qo_all_sum.dataUpdatedAt]
  );
  const filt_sum = useMemo(
    () => getv(qo_filt_sum, "data.result"),
    [qo_filt_sum.dataUpdatedAt]
  );
  const betslist = useMemo(
    () =>
      getv(qo_bets, "data.result") || {
        bets: [],
        vmap: {},
        rmap: {},
        hmap: {},
      },
    [qo_bets.dataUpdatedAt]
  );

  const lcon = {
    filt,
    set_filt,

    all_sum,
    filt_sum,
    betslist,
    qo_bets,
  };

  return (
    <AdminWrapper>
      <AdLedgerContext.Provider value={lcon}>
        <div className="h-page">
          <div className="w-[80rem] max-w-[98vw] mx-auto">
            <div className="h-[3rem]"></div>
            <div>
              <p className="resp-text-1  text-center font-digi text-acc0">
                Admin Ledger
              </p>
            </div>
            <div className="h-[1rem]"></div>
            <Summary />

            <FiltSection />

            <BetsTableSection />
          </div>
        </div>
      </AdLedgerContext.Provider>
    </AdminWrapper>
  );
}

export default AdminLedger;
