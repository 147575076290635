import React, { useEffect, useMemo, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { Tag } from "./utilityComps.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { useAppContext } from "../App.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  faAngleDown,
  faBank,
  faBox,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faDna,
  faFlag,
  faFlagCheckered,
  faGear,
  faGhost,
  faHome,
  faMarker,
  faPencil,
  faPerson,
  faSatellite,
  faSatelliteDish,
  faSignOut,
  faTrash,
  faTrophy,
  faUser,
  faVault,
  faVrCardboard,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";
import { getv, jstr } from "../utils/utils.js";
import { MoVariants } from "../utils/motion_helper.js";
import {
  AccountBalanceNav,
  useAccountContext,
} from "../wrappers/AccountWrapper.js";
import _ from "lodash";
import Login from "../views/Login.js";

export const logoimg = "https://i.imgur.com/ngmPy1x.png";

export const Logo = () => {
  return (
    <div className="fr-cc">
      {/* <div className="xs:w-[2rem] xs:h-[2rem] lg:w-[4rem] lg:h-[4rem] xs:ml-1 lg:ml-4 resp-p-2">
        <img className="h-full object-contain" src={logoimg} alt="" />
      </div> */}
      <div className="xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem] xs:ml-1 lg:ml-4 resp-p-2"></div>
      <div className="flex xs:flex-col lg:flex-row justify-start items-baseline">
        <span className="text-acc0 font-digi font-sebold resp-text-1">
          FairEx
        </span>
      </div>
    </div>
  );
};

const NavLogo = () => {
  return (
    <Link to="/">
      <Logo />
    </Link>
  );
};

const AccountDrop = ({ options, size = "lg" }) => {
  const aucon = useAuthContext();
  const { vault, auth, admin_auth } = aucon;

  const vault_name = getv(aucon, "vaultdoc.vault_name");

  const [hover, set_hover] = useState(false);

  const linkcn = "text-white hover:text-acc0 resp-py-1 resp-px-2 resp-text-1";

  return (
    <div
      className="cursor-pointer"
      {...{
        ...(size == "lg" ? { onMouseLeave: () => set_hover(false) } : {}),
        ...(size == "xs" ? { onClick: () => set_hover(!hover) } : {}),
      }}
    >
      <div
        {...{
          ...(size == "lg" ? { onMouseEnter: () => set_hover(true) } : {}),
          ...(size == "xs" ? {} : {}),
        }}
        className="fr-cc resp-mx-4 resp-gap-1 relative"
      >
        <div className="xs:w-[1.5rem] xs:h-[1.5rem] lg:w-[3rem] lg:h-[3rem] bg-dark fc-cc rounded-full">
          <FontAwesomeIcon className="text-acc0 resp-text-1" icon={faUser} />
        </div>
        <FontAwesomeIcon className="text-acc0 resp-text-1" icon={faAngleDown} />
      </div>
      <motion.div
        variants={MoVariants.show_hide}
        animate={hover ? "visible" : "hidden"}
        className="absolute min-w-[15rem] w-[15rem] bg-reg border border-dark top-[3rem] right-0 rounded-md resp-p-4 z-30"
      >
        <p className="font-digi text-acc0 resp-py-1 resp-px-2 mb-2 resp-text-1">
          {vault_name}
        </p>
        <hr />
        {options.map(([to, txt, cn, fn, ico]) => {
          return (
            <div className="w-full resp-py-2">
              <Link
                onClick={() => {
                  console.log(txt, fn);
                  if (fn) fn();
                }}
                to={to}
                className={twMerge(cn, "fr-sc resp-gap-2")}
              >
                <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                <span>{txt}</span>
              </Link>
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};

const linkcn = "hover:text-acc0 font-digi resp-px-2 resp-text-0 fr-sc h-full";

const DropLink = ({ txt, links }) => {
  const [hover, set_hover] = useState(false);
  const history = useNavigate();
  return (
    <div
      onMouseLeave={() => {
        set_hover(false);
      }}
      className="w-max h-full cursor-pointer relative"
    >
      <Tag
        onMouseEnter={() => {
          set_hover(true);
        }}
        onClick={() => {
          let url = links[0][0];
          history(url);
        }}
        className={twMerge(linkcn, "fr-sc resp-gap-1")}
      >
        <span>{txt}</span>
        <FontAwesomeIcon
          className={twMerge(
            "text-slate-600 resp-text-1 transition duration-300",
            hover ? "rotate-180" : "",
          )}
          icon={faAngleDown}
        />
      </Tag>
      <motion.div
        variants={MoVariants.show_hide}
        animate={hover ? "visible" : "hidden"}
        className="absolute min-w-[15rem] w-[15rem] bg-reg/80 border border-dark top-[3rem] left-0 rounded-md resp-p-4 z-30"
      >
        {links.map(([to, txt, cn, fn, ico]) => {
          return (
            <div className="w-full resp-py-2">
              <Link
                onClick={() => {
                  if (fn) fn();
                  set_hover(false);
                }}
                to={to}
                className={twMerge(cn, "fr-sc resp-gap-2")}
              >
                <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                <span>{txt}</span>
              </Link>
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};

const Nav = ({ loginpop, set_loginpop }) => {
  const { path, history } = useAppContext();
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const acc = useAccountContext();
  const { vault, auth_admin } = aucon;
  const vault_name = getv(acc, "vdoc.vault_name");
  // console.log(vault, vault_name, acc)

  const smlinkcn =
    "hover:text-acc0 font-mon resp-px-2 resp-text--2 fr-sc h-full";
  const activecn = "border-b border-acc0 text-acc0";

  const droplinks = [
    ...(auth_admin
      ? [
          [
            "Admin",
            [
              [`/admin/bets`, "Bets", linkcn, null, faBank],
              [`/admin/leaderboard`, "Leaderboard", linkcn, null, faTrophy],
            ],
          ],
        ]
      : []),
  ];

  const links = [
    [`/`, "Home", linkcn, null, faHome],
    [`/bets/${vault}`, "Bets", linkcn, null, faBank],
    [`/leaderboard`, "leaderboard", linkcn, null, faBank],
    [`https://fbike.dnaracing.run`, "DNA Races", linkcn, null, faCircle],

    // ["/splice-arena", "Splicing", linkcn, () => {}, faDna],
  ];

  const options = [
    // ["/jackpot", "JackPot", linkcn, null, faBox],
    // [`/ledger`, "Ledger", linkcn, null, faBank],
    [`/acc/${vault}`, "Account", linkcn, null, faGear],
    [
      null,
      "clear cache",
      twMerge(linkcn, "text-orange-300"),
      clearSiteData,
      faTrash,
    ],
    [null, "Logout", twMerge(linkcn, "text-red-300"), aucon.logout, faSignOut],
  ];

  return (
    <>
      <div
        className={twMerge(
          " w-[100vw] xs:block lg:hidden",
          // path == "/" ? "absolute h-0 top-0 z-10" : ""
        )}
      >
        <div
          className={twMerge(
            "xs:h-[2rem] lg:h-[4rem]",
            "bg-reg flex flex-row justify-center items-center resp-gap-2",
            // path == "/" ? "bg-opacity-40" : ""
          )}
        >
          <NavLogo />
          <div className="flex-1"></div>
          {/* {vault_name && (
            <Tag className="resp-text--2 rounded-full font-mon bg-acc0/30">
              {vault_name}
            </Tag>
          )} */}
          <AccountBalanceNav />

          {aucon.auth == false && (
            <>
              <div className="flex-1"></div>
              <Tag
                onClick={() => {
                  set_loginpop(true);
                }}
                className="bg-acc0 resp-p-2 resp-text--3 font-digi text-black resp-mx-4"
              >
                Login
              </Tag>
            </>
          )}

          {aucon.auth == true && acc && (
            <>
              <AccountDrop
                {...{
                  size: "xs",
                  options: [
                    ..._.chain(droplinks).map(1).flatten().value(),
                    ...links,
                    ...options,
                  ],
                }}
              />
            </>
          )}
        </div>
        <div className="resp-p-1 bg-reg min-h-[1.7rem] fr-cc"></div>
      </div>

      <div
        className={twMerge(
          " w-[100vw] xs:hidden lg:block",
          // path == "/" ? "absolute h-0 top-0 z-10" : ""
        )}
      >
        <div
          className={twMerge(
            "h-[4rem] bg-reg flex flex-row justify-center items-center resp-gap-2",
            // path == "/" ? "bg-opacity-40" : ""
          )}
        >
          <NavLogo />
          {aucon.auth == true && (
            <>
              <div className="w-[4rem]"></div>

              {droplinks.map(([txt, links]) => {
                return <DropLink {...{ key: txt, txt, links }} />;
              })}

              {links.map(([to, txt]) => {
                let target = to && to.startsWith("/") ? "_self" : "_blank";
                return (
                  <Link
                    key={to}
                    to={to}
                    target={target}
                    className={twMerge(linkcn, path == to ? activecn : "")}
                  >
                    <span>{txt}</span>
                  </Link>
                );
              })}
              <div className="flex-1"></div>

              {vault_name && (
                <Tag className="resp-text--2 font-digi rounded-full font-mon text-acc0">
                  {vault_name}
                </Tag>
              )}

              <AccountBalanceNav />

              <div className="w-max">
                <AccountDrop {...{ size: "lg", options }} />
              </div>
            </>
          )}
          {aucon.auth == false && (
            <>
              <div className="flex-1"></div>
              <Tag
                onClick={() => {
                  set_loginpop(true);
                }}
                className="bg-acc0 resp-p-2 font-digi text-black resp-mx-4"
              >
                Login
              </Tag>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const clearSiteData = async () => {
  // Clear localStorage
  localStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear IndexedDB (example for a database named 'myDatabase')
  const req = indexedDB.deleteDatabase("gltfCache");
  req.onsuccess = function () {
    console.log("Deleted database successfully");
  };
  req.onerror = function () {
    console.log("Couldn't delete database");
  };
  req.onblocked = function () {
    console.log("Couldn't delete database due to the operation being blocked");
  };

  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

function Layout(props) {
  const [loginpop, set_loginpop] = useState(false);

  return (
    <div className="relative">
      <Nav {...{ loginpop, set_loginpop }} />
      <main
        className={
          twMerge("relative")
          // "md:max-w-[80rem] md:w-full mx-auto",
          // "xs:max-w-[95vw] mx-auto"
        }
      >
        {loginpop && (
          <div className="absolute z-[3000] top-[15vh] left-[50%] transform translate-x-[-50%] h-0">
            <Login onclose={() => set_loginpop(false)} />
          </div>
        )}
        <>{props.children}</>
      </main>
    </div>
  );
}

export default Layout;
