import React, {
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { useNowContext } from "../App";
import { Loader01c } from "../components/anims";
import { PageWrapper } from "../components/ShortComps";
import { Card, TokenIcon } from "../components/utilityComps";
import {
  qissuccess,
  q_leaderboard_info,
  q_leaderboard_leader,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { from_time_mini, getv, iso, iso_format, nano } from "../utils/utils";

export const LeaderBoardContext = createContext({});
export const useLeaderBoardContext = () => useContext(LeaderBoardContext);

export const Leaderboard = () => {
  const [qi, ql] = useQueries([q_leaderboard_info(), q_leaderboard_leader()]);
  const i = useMemo(() => getv(qi, "data.result"), [qi.dataUpdatedAt]);
  const l = useMemo(() => getv(ql, "data.result"), [ql.dataUpdatedAt]);
  // const now = useMemo(() => iso(), []);
  const { now } = useNowContext();

  return (
    <>
      <PageWrapper page_title_fn={() => `Leaderboard`}>
        <div class="resp-text-2 font-digi text-center">Leaderboard</div>
        {(qi.isLoading || ql.isLoading) && <Loader01c />}
        {qissuccess(qi) && (
          <Card className={"my-2 w-full"}>
            <div class="resp-text-1 font-digi text-center my-1">{i.name}</div>
            <div class="fr-sc">
              <div class="fc-ss">
                <div class="fr-sc">
                  {nano(now) > nano(i.st) ? (
                    <>
                      <span>Started at {iso_format(i.st)}</span>
                    </>
                  ) : (
                    <>
                      <span>Starts in {from_time_mini(i.st)}</span>
                    </>
                  )}
                </div>
                <div class="fr-sc">
                  {nano(now) > nano(i.ed) ? (
                    <>
                      <span>Ended at {iso_format(i.ed)}</span>
                    </>
                  ) : (
                    <>
                      <span>Ends in {from_time_mini(i.ed)}</span>
                    </>
                  )}
                </div>
              </div>
              <div class="flex-1"></div>
              <span className="resp-text--1 text-acc0 font-digi">
                {i.status}
              </span>
            </div>
          </Card>
        )}
        {qissuccess(ql) && (
          <div class="w-full min-h-[4rem] my-2 resp-text-1">
            <table
              className={twMerge(
                tablecn.table,
                "w-full thintdrowp4-table-acc0",
              )}
            >
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {l.ar.map((r) => {
                  if (!r) return null;
                  return (
                    <tr>
                      <td>#{r.rank}</td>
                      <td>
                        <div class="fc-ss">
                          <span>{getv(l.vmap, `${r.vault}`)}</span>
                          <span className="resp-text--2">{r.vault}</span>
                        </div>
                      </td>
                      <td>
                        <div class="fr-cc">
                          <span
                            className={twMerge(
                              "font-mono resp-text-1 flex-1 text-right",
                              r.profit > 0 ? "text-green-400" : "text-red-400",
                            )}
                          >
                            {r.profit}
                          </span>
                          <TokenIcon token={r.token} size="md" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export default Leaderboard;
