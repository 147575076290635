import React, { useEffect, useState } from "react";
import { Card, Tag, TokenIcon } from "../components/utilityComps.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { twMerge } from "tailwind-merge";
import { contractAddress_list } from "../contracts/constants.js";
import { mm_asset_signer } from "../contracts/contract_funcs.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { dec, jstr, nils, toeth, tofeth } from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

const TokenAllowanceCard = ({ token }) => {
  const accon = useAccountContext();
  const { vault, auth } = accon;
  const [alw, set_alw] = useState(null);

  const textcn = (token) =>
    token == "WETH" ? "text-pink-400" : token == "DEZ" ? "text-acc0" : "";
  const bgcn = (token) =>
    token == "WETH" ? "bg-pink-400/40" : token == "DEZ" ? "bg-acc0/40" : "";
  const shadowcn = (token) =>
    token == "WETH"
      ? "shadow shadow-pink-400"
      : token == "DEZ"
      ? "shadow shadow-acc0"
      : "";

  const paytokenaddr = useMemo(() => {
    let v =
      contractAddress_list[
        token == "WETH" ? "weth" : token == "DEZ" ? "dez" : null
      ];
    if (nils(v)) return "";
    v = v.toLowerCase();
    return v;
  }, [token]);

  const get_allowace = async () => {
    try {
      let pcon = await mm_asset_signer(null, 20, paytokenaddr);
      let alw = await pcon.allowance(vault, contractAddress_list.racestake);
      alw = tofeth(alw);
      return alw;
      console.log("get_allowace");
      return 0;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  const upd_alw = async () => {
    let a = await get_allowace();
    console.log("upd_alw", a);
    set_alw(a);
  };

  useEffect(() => {
    if (!auth && nils(vault)) return;
    upd_alw();
  }, [jstr({ auth, vault })]);

  const add_alw = async () => {
    try {
      const paytokencon = await mm_asset_signer(null, 20, paytokenaddr);
      let bal = await paytokencon.balanceOf(vault);
      bal = dec(
        parseFloat(tofeth(bal)) * 0.95,
        token == "WETH" ? 4 : token == "DEZ" ? 0 : 6
      );
      let tx = await paytokencon.approve(vault, toeth(bal));
      await tx.wait();
      upd_alw();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card className={twMerge("bg-reg w-full", shadowcn(token))}>
      <div className="fr-cc resp-gap-2">
        <TokenIcon token={token} size="md" />
        <p
          className={twMerge(
            "text-center resp-text-1 font-digi",
            textcn(token)
          )}
        >
          {token}
        </p>
      </div>

      <div className="fr-cc resp-gap-2 my-2">
        {nils(alw) ? (
          "--"
        ) : (
          <>
            <span>
              {dec(alw, token == "WETH" ? 4 : token == "DEZ" ? 0 : 4)}
            </span>
            <span>{"tokens"}</span>
          </>
        )}
        <Tag
          onClick={() => {
            upd_alw();
          }}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </Tag>
      </div>
      <div className="fr-cc resp-gap-2 my-2">
        <Tag
          onClick={() => {
            add_alw();
          }}
          className={twMerge(bgcn(token), "resp-text--2")}
        >
          Add Allowance
        </Tag>
      </div>
    </Card>
  );
};

function Allowance() {
  const { mm_connected } = useMetaMaskContext();

  return (
    <div className="h-page">
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[3rem]"></div>
        <p className="text-center text-acc0 resp-text-1">Allowance</p>
        <div className="h-[1rem]"></div>
        {mm_connected === true && (
          <>
            <div className="grid grid-cols-2 resp-gap-2 my-2">
              <TokenAllowanceCard token="WETH" />
              <TokenAllowanceCard token="DEZ" />
            </div>
            <div className="resp-text--1">
              <p className="text-center text-yellow-300">
                You dont need to add allowance again and again if you see token
                qty more than enoughs
              </p>
              <p className="text-center text-yellow-300">
                If you set allowance, wait for a few minutes to reflect,
                requesting again will reset the allowance
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Allowance;
